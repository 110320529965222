import {defineStore} from 'pinia';
import {getUrlParams} from "~/composables/urlMixin";
import useGtmTrackEvent from "~/composables/gtmTracking";

const ENDETTEMENT_MAX_TAUX = 0.35;
const LOYER_COMPTABILISE_TAUX = 0.7;
const RENTABILITE_INVESTISSEMENT_TAUX = 0.035;
const FRAIS_NOTAIRE_TAUX = 0.03;

// prendre les valeurs de meilleutaux en fumeur https://www.meilleurtaux.com/assurance-de-pret/le-guide-de-l-assurance-de-pret/taux-assurance-pret-immobilier.html
const TAUX_ASSURANCE_20_ANS = 0.0008;
const TAUX_ASSURANCE_30_ANS = 0.0013;
const TAUX_ASSURANCE_40_ANS = 0.0025;
const TAUX_ASSURANCE_50_ANS = 0.0033;

function defaultInvestisseur() {
    return {
        // 2
        revenuMontant: null,
        revenuType: null,
        revenuPeriode: null,
        revenuAutre: null,
        revenuAutreItems: [],

        // 3
        locOuProp: null,
        mensualitesLoyer: null,
        hasPretImmobilier: null,
        mensualitesPretImmobilier: null,
        dateFinPretImmobilier: null,
        autresCharges: null,
        autresChargesItems: [],

        // 4
        impotsMontant: null,
        apportMontant: null
    }
}

export const useSimulateurStore = defineStore({
    id: 'simulateur-store',
    state: () => ({
        isEdit: false,
        loading: false,

        id: null,
        titre: null,

        results: {
            min: null,
            max: null,
            nbInvest: null,
            infos: {
                // 1
                age: null,
                etranger: null,
                pays: null,
                commune: null,
                nbInvest: null,
                objet: null,

                // 2
                viveEnsemble: null,
                impotsEnsemble: null,
                investisseurs: [
                    defaultInvestisseur()
                ]
            },
            fiscalite: "LMNP, PINEL",
            conseil: null,
            version: 2,
            mobile: null,
            prenom: null,
            nom: null,
            email: null,
            codePostal: null,
            contactValority: {}
        },

        sliders: {
            TAUX_CREDIT: 3.5,
            TAUX_MIN: 2,
            TAUX_MAX: 5,
            DUREE_CREDIT: 20,
            INDEX_DUREE_CREDIT: 0,
            REVENUS: 0,
            APPORT: 0,
            CHARGES: 0
        }
    }),
    actions: {
        addInvestor() {
            this.results.infos.investisseurs.push(defaultInvestisseur());
        },
        deleteInvestor() {
            this.results.infos.investisseurs.splice(1);
        },
        save(update = false) {
            this.loading = true;

            useGtmTrackEvent({
                event: 'simulation_submitted',
                investment_capacity: this.results.max.toString(),
                type_investment: this.results.infos.objet === 'invest_loc' ? 'investissement-locatif' : 'residence-principale',
                user_id: localStorage.getItem('userToken'),
                user_data: {
                    phone: this.results.mobile
                }
            });

            const url = '/mon-compte/simulation/save' + (update === true ? '/' + this.id : '');

            // on tej cet objet inutile si pas le simu valority
            const results = this.results;
            delete results.contactValority;

            return useNuxtApp().$axios.post(url, {
                'result': results,
                'userToken': localStorage.getItem('userToken')
            }).then(({data}) => {
                this.id = data.id;
                this.titre = data.titre;
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                this.isEdit = false;
                this.loading = false;
            });
        },
        saveValority() {
            this.loading = true;

            return useNuxtApp().$axios.post(
                '/mon-compte/simulation/save-valority',
                {
                    'result': this.results,
                    'params': getUrlParams(window.location)
                }
            ).catch((e) => {
                console.error(e);
            }).finally(() => {
                this.loading = false;
            });
        },
        init() {
            this.sliders.REVENUS = Math.round(this.getMontantRevenusMensuel);
            this.sliders.APPORT = Math.round(this.getApport);
            this.sliders.CHARGES = Math.round(this.getMontantChargesMensuel);
        },
        calcul(results = null, sliders = null) {
            if (results === null) {
                results = this.results;
            }

            results.min = Math.floor(this.calculateCapaciteInvestissement(sliders) / 1000) * 1000;
            results.max = Math.floor(results.min * 1.2 / 1000) * 1000;

            if (results.min > 350000) {
                results.max = results.min;
                results.min = Math.floor(results.min / 1.1);
            }

            results.conseil = "/catalogue?budgetMax=" + results.max;
            results.nbInvest = results.infos.nbInvest;

            return results;
        },
        calculateCapaciteInvestissement(sliders = null) {
            if (sliders === null) {
                sliders = this.sliders;
            }

            let revenuMensuelMontant = sliders.REVENUS;
            let chargeMensuelMontant = sliders.CHARGES;

            let ageEmprunteur = this.results.infos.age;

            if (isNaN(ageEmprunteur)) {
                throw new Error('Erreur age emprunteur');
            }

            let tauxAssurance = 0;

            switch (true) {
                case ageEmprunteur >= 50:
                    tauxAssurance = TAUX_ASSURANCE_50_ANS;
                    break;
                case ageEmprunteur >= 40:
                    tauxAssurance = TAUX_ASSURANCE_40_ANS;
                    break;
                case ageEmprunteur >= 30:
                    tauxAssurance = TAUX_ASSURANCE_30_ANS;
                    break;
                case ageEmprunteur < 30:
                    tauxAssurance = TAUX_ASSURANCE_20_ANS;
                    break;
            }

            let TAEGAnnuel = (sliders.TAUX_CREDIT / 100) + tauxAssurance;

            // super formule pour calculer le montant de la capacité  d'emprunt avec prise en compte de la rentabilité de l'investissement
            // source formule : http://images.math.cnrs.fr/Emprunts-mensualites-interet-taux-TEG-risque-de-taux.html

            // lexique variables
            // Rev = somme des revenu net
            // Ch = sommes des charges net
            // C : capacite emprunt
            // M : mensualite credit
            // S : Taux seuil endettement
            // tx : taux annuel crédit TAEG
            // t : taux MENSUEL crédit TAEG
            // R : taux rentabilité annuel investissement
            // L : taux de loyer retenu
            // n : nombre de mensualité

            // formule (1) calcul capacite emprunt
            // C = ( M / t ) * ( 1 - ( 1 / ( t + 1 )^n ) )

            // formule (2) calcul mensualité crédit
            // M = ( C * t * ( 1 + t )^n ) / ( ( 1 + t )^n - 1 )

            // formule (3) calcul revenu mensuel de l'investissement
            // R = C * R * L / 12

            // il faut faire un calcul ou il faut calculer C tel que
            // charges de l'investissement - revenu de l'investissement = Reste mensuel avant d'atteindre le seuil endettement pour l'investissement

            // Exemple : revenu 4000, charges 1000,
            // reste mensuel pour invest = 4000 * taux endettement max (35%) - 1000 = 400
            // il peut depenser 400€ pour son credit d'invest

            // son crédit va lui rapporte formule (3) et va lui couter formule (2)
            // en mélangeant les 2 formules on obtient
            // C = [ 12 * ( S * Rev - Ch ) ] / [ ( 12 * t * ( 1 + t )^n ) - R * L ( ( 1 + t )^n - 1 ) ]

            let tx = TAEGAnnuel / 12;
            let S = ENDETTEMENT_MAX_TAUX;
            let n = (parseInt(ageEmprunteur) >= 55) ? sliders.DUREE_CREDIT * 9 : sliders.DUREE_CREDIT * 12;
            let Rev = revenuMensuelMontant;
            let Ch = chargeMensuelMontant;
            let R = RENTABILITE_INVESTISSEMENT_TAUX;
            let L = LOYER_COMPTABILISE_TAUX;

            let capital = (12 * (S * Rev - Ch) * (Math.pow((1 + tx), n) - 1)) / ((12 * tx * Math.pow((1 + tx), n)) - R * L * (Math.pow((1 + tx), n) - 1));

            capital = capital < 0 ? 0 : capital;

            // ajout apport
            capital += sliders.APPORT;

            // frais de notaire
            capital *= 1 - FRAIS_NOTAIRE_TAUX;

            let margeSup = 1.2;

            if (Math.floor(capital / 1000) * 1000 > 350000) {
                margeSup = 1;
            }

            localStorage.setItem("capaciteInvesMax", Math.floor((Math.floor(capital / 1000) * 1000) * margeSup / 1000) * 1000);

            return capital;
        },
        isBudgetOk(results = null) {
            if (results === null) {
                results = this.results;
            }

            return !isNaN(results.min) && (Math.floor(results.min * 1.2 / 1000) * 1000) > 80000;
        }
    },
    getters: {
        getMontantRevenusMensuel() {
            let revenuTotalMensuelNet = 0;

            // INVESTISSEUR PRINCIPAL
            revenuTotalMensuelNet = (this.results.infos.investisseurs[0].revenuPeriode === 'mois') ?
                parseFloat(this.results.infos.investisseurs[0].revenuMontant)
                : parseFloat(this.results.infos.investisseurs[0].revenuMontant) / 12;

            if (this.results.infos.investisseurs[0].revenuType === 'brut') {
                revenuTotalMensuelNet *= 0.77; // si revenu brut, prelevement 23% sur le brut
            }

            // revenu autre
            if (this.results.infos.investisseurs[0].revenuAutre === 'avez') {
                this.results.infos.investisseurs[0].revenuAutreItems.forEach((autreRevenu) => {
                    revenuTotalMensuelNet += (autreRevenu.periode === 'mois') ? parseFloat(autreRevenu.montant) : parseFloat(autreRevenu.montant) / 12;
                });
            }

            // CO-INVESTISSEUR
            if (this.results.infos.nbInvest === 2 && this.results.infos.investisseurs.length === 2) {
                let revenuTotalMensuelCoInvest = (this.results.infos.investisseurs[1].revenuPeriode === 'mois') ?
                    parseFloat(this.results.infos.investisseurs[1].revenuMontant) :
                    parseFloat(this.results.infos.investisseurs[1].revenuMontant) / 12;

                if (this.results.infos.investisseurs[1].revenuType === 'brut') {
                    revenuTotalMensuelCoInvest *= 0.77; // si revenu brut, prelevement 23% sur le brut
                }

                revenuTotalMensuelNet += revenuTotalMensuelCoInvest;
            }

            return revenuTotalMensuelNet;
        },
        getMontantChargesMensuel() {
            // INVESTISSEUR PRINCIPAL
            let chargesMensuelNet = 0;

            if (this.results.infos.investisseurs[0].locOuProp === 'locataire') {
                chargesMensuelNet += parseFloat(this.results.infos.investisseurs[0].mensualitesLoyer);
            }

            if (this.results.infos.investisseurs[0].hasPretImmobilier === 'avez') {
                // si date fin pret immobilier dans moins de 2 ans, non pris en compte
                let dateFin = this.results.infos.investisseurs[0].dateFinPretImmobilier;

                if (dateFin !== 'moins_deux_ans') {
                    chargesMensuelNet += parseFloat(this.results.infos.investisseurs[0].mensualitesPretImmobilier);
                }
            }

            // impots (déjà pris en compte dans les revenus net)
            // chargesMensuelNet += parseFloat(this.results.infos.investisseurs[0].impotsMontant) / 12;

            // charge autre
            if (this.results.infos.investisseurs[0].autresCharges === 'avez') {
                this.results.infos.investisseurs[0].autresChargesItems.forEach((autreCharge) => {
                    let dateFin = autreCharge.dateFin;
                    if (dateFin !== 'moins_deux_ans') {
                        chargesMensuelNet += parseFloat(autreCharge.mensualite);
                    }
                });
            }

            // CO-INVESTISSEUR
            let chargesMensuelNetCoInvest = 0;

            if (this.results.infos.nbInvest === 2 && this.results.infos.investisseurs.length === 2) {
                if (this.results.infos.viveEnsemble === 'separement' && this.results.infos.investisseurs[1].locOuProp === 'locataire') {
                    chargesMensuelNetCoInvest += parseFloat(this.results.infos.investisseurs[1].mensualitesLoyer);
                }

                if (this.results.infos.viveEnsemble === 'separement' && this.results.infos.investisseurs[1].hasPretImmobilier === 'avez') {
                    // si date fin pret immobilier dans moins de 2 ans, non pris en compte
                    let dateFin = this.results.infos.investisseurs[1].dateFinPretImmobilier;

                    if (dateFin !== 'moins_deux_ans') {
                        chargesMensuelNetCoInvest += parseFloat(this.results.infos.investisseurs[1].mensualitesPretImmobilier);
                    }
                }

                // impots
                // if (this.results.infos.impotsEnsemble === 'separement') {
                //     chargesMensuelNetCoInvest += parseFloat(this.results.infos.investisseurs[1].impotsMontant) / 12;
                // }
            }

            chargesMensuelNet += chargesMensuelNetCoInvest;

            return chargesMensuelNet;
        },
        getApport() {
            return parseInt(this.results.infos.investisseurs[0].apportMontant)
        }
    }
});
